import BasePlugin from '../BasePlugin'

export default class SendPetitionOnReviewCommand extends BasePlugin {
  async execute () {
    let petitionId = {
      'petitionId': this.context.getModel().id
    }
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SendPetitionOnReviewCommand`,
      petitionId,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  }
}
